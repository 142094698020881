import {
  AppManagerEvent,
  PageActionsEvent,
  PageSettingsTab,
  AppManifestBuilder,
} from './types/manifest';
import {
  PAYMENT_PAGE_MANIFEST_KEY,
  CHALLENGES_LIST_PAGE_MANIFEST_KEY,
  CHALLENGES_PAGE_MANIFEST_KEY,
  CHALLENGE_PAYMENT_PAGE_TYPE,
  CHALLENGE_THANK_YOU_PAGE_TYPE,
  CHALLENGES_LIST_PAGE_TYPE,
  CHALLENGES_LIST_WIDGET_TYPE,
  CHALLENGE_PAGE_TYPE,
  MA_PROGRAMS_LIST_PAGE_TYPE,
  PARTICIPANT_PAGE_MANIFEST_KEY,
  PARTICIPANT_PAGE_TYPE,
} from './app-config';
import { GfppDesktopBuilder } from '@wix/app-manifest-builder';
import type { Experiments } from '@wix/fe-essentials-editor';
import { Challenges } from './types/Experiments';

// https://bo.wix.com/wix-docs/client/client-frameworks#editor-platform.articles.pages-manifest
export async function getChallengesManifest<T extends Experiments>(params: {
  appManifestBuilder: AppManifestBuilder;
  translations: any;
  openDashboard: (id: AppManagerEvent) => void;
  experiments: T;
}) {
  const { appManifestBuilder, translations, openDashboard, experiments } =
    params;

  function goToDashboardAction() {
    return {
      label: translations.t('challenge.settings.design.manage-challenges'),
      onClick: () => {
        openDashboard(AppManagerEvent.OPEN_DASHBOARD);
      },
    };
  }

  function defaultGfppButtonSettings(gfpp: GfppDesktopBuilder<'widget'>) {
    gfpp.set('mainAction1', goToDashboardAction()).set('mainAction2', {
      behavior: 'DEFAULT1',
    });
  }

  return (
    appManifestBuilder
      // Start Pages Panel Tab
      .configurePagesTab((pagesTabBuilder) => {
        pagesTabBuilder
          .set({
            displayName: translations.t(
              'editor.pages.challenges-pages-tab-name',
            ),
            helpId: '6c8df9e5-1bc0-4b1c-93a7-6f1ea34cefc7',
          })
          .addAction({
            title: translations.t(
              'editor.manage-my-apps.manage-manage-challenges.cta2',
            ),
            event: AppManagerEvent.OPEN_DASHBOARD,
            icon: 'settingsAction',
          })
          .addAction({
            title: translations.t('challenge.page.delete'),
            event: PageActionsEvent.REMOVE,
            icon: 'deleteRadio',
          })
          .setGrouping('none');
      })
      // END Pages Panel Tab
      // START Pages
      .configurePages((pagesBuilder) => {
        pagesBuilder.configureState(
          CHALLENGES_LIST_PAGE_MANIFEST_KEY,
          (stateBuilder) => {
            stateBuilder
              .set({
                orderIndex: 3,
              })
              .addAction({
                type: 'page_rename',
              })
              .addSettingsTab(
                {
                  title: translations.t('challenge.page.info'),
                  type: PageSettingsTab.PAGE_INFO,
                },
                {
                  title: translations.t('challenge.page.layout'),
                  type: PageSettingsTab.LAYOUT,
                },
                {
                  title: translations.t('challenge.page.permissions'),
                  type: PageSettingsTab.PERMISSIONS,
                },
                {
                  title: translations.t('challenge.page.seo'),
                  type: PageSettingsTab.SEO,
                },
              );
          },
        );

        pagesBuilder.configureState(
          CHALLENGES_PAGE_MANIFEST_KEY,
          (stateBuilder) => {
            stateBuilder
              .set({
                orderIndex: 2,
              })
              .addAction({
                type: 'page_rename',
              })
              .addSettingsTab(
                {
                  title: translations.t('challenge.page.info'),
                  type: PageSettingsTab.PAGE_INFO,
                },
                {
                  title: translations.t('challenge.page.layout'),
                  type: PageSettingsTab.LAYOUT,
                },
                {
                  title: translations.t('challenge.page.permissions'),
                  type: PageSettingsTab.PERMISSIONS,
                },
              );
          },
        );

        pagesBuilder.configureState(
          PAYMENT_PAGE_MANIFEST_KEY,
          (stateBuilder) => {
            stateBuilder
              .set({
                orderIndex: 0,
              })
              .addAction({
                type: 'page_rename',
              })
              .addSettingsTab(
                {
                  title: translations.t('challenge.page.info'),
                  type: PageSettingsTab.PAGE_INFO,
                },
                {
                  title: translations.t('challenge.page.layout'),
                  type: PageSettingsTab.LAYOUT,
                },
              );
          },
        );
        if (experiments.enabled(Challenges.enableParticipantPageInEditor)) {
          pagesBuilder.configureState(
            PARTICIPANT_PAGE_MANIFEST_KEY,
            (stateBuilder) => {
              stateBuilder
                .set({
                  orderIndex: 1,
                })
                .addAction({
                  type: 'page_rename',
                })
                .addSettingsTab(
                  {
                    title: translations.t('challenge.page.info'),
                    type: PageSettingsTab.PAGE_INFO,
                  },
                  {
                    title: translations.t('challenge.page.layout'),
                    type: PageSettingsTab.LAYOUT,
                  },
                );
            },
          );
        }
      })
      // END Pages
      // START controllerStageDate
      .configureWidget(CHALLENGE_PAYMENT_PAGE_TYPE, (widgetBuilder) => {
        widgetBuilder.set({
          displayName: translations.t('editor.initial.page-name.payment-page'),
        });

        const showSettingsButton = experiments.enabled(
          Challenges.showSettingsPanel,
        );

        widgetBuilder
          .gfpp()
          .set('mainAction1', goToDashboardAction())
          .set('mainAction2', {
            behavior: showSettingsButton ? 'DEFAULT1' : 'HIDE',
          });
      })
      .configureWidget(CHALLENGE_THANK_YOU_PAGE_TYPE, (widgetBuilder) => {
        widgetBuilder.set({
          displayName: translations.t(
            'editor.initial.page-name.thank-you-page',
          ),
        });
      })
      .configureWidget(CHALLENGES_LIST_PAGE_TYPE, (widgetBuilder) => {
        widgetBuilder.set({
          displayName: translations.t(
            'editor.initial.page-name.challenge-list-page',
          ),
        });
        defaultGfppButtonSettings(widgetBuilder.gfpp());
      })
      .configureWidget(CHALLENGES_LIST_WIDGET_TYPE, (widgetBuilder) => {
        widgetBuilder.set({
          displayName: translations.t(
            'editor.initial.page-name.challenge-list-page',
          ),
        });
        defaultGfppButtonSettings(widgetBuilder.gfpp());
      })
      .configureWidget(MA_PROGRAMS_LIST_PAGE_TYPE, (widgetBuilder) => {
        widgetBuilder.set({
          displayName: translations.t('members-area.title'),
        });
        defaultGfppButtonSettings(widgetBuilder.gfpp());
      })
      .configureWidget(CHALLENGE_PAGE_TYPE, (widgetBuilder) => {
        widgetBuilder.set({
          displayName: translations.t(
            'editor.initial.page-name.challenge-page',
          ),
        });
        defaultGfppButtonSettings(widgetBuilder.gfpp());
      })
      .configureWidget(PARTICIPANT_PAGE_TYPE, (widgetBuilder) => {
        widgetBuilder.set({
          displayName: translations.t(
            'editor.initial.page-name.participant-page',
          ),
        });
        defaultGfppButtonSettings(widgetBuilder.gfpp());
      })
      // END controllerStageDate
      // START Business Panel
      .configureManagementActions((managementActionsBuilder) => {
        managementActionsBuilder.mainActions().addAction({
          title: translations.t('editor.app-manager.go-to-dashboard'),
          actionId: AppManagerEvent.OPEN_DASHBOARD,
          icon: 'appManager_settingsAction',
        });

        managementActionsBuilder.customActions().addAction(
          {
            title: translations.t('editor.manage-my-apps.quick-actions.cta1'),
            actionId: AppManagerEvent.OPEN_CREATE_CHALLENGE,
            icon: 'appManager_addElementsAction',
            type: 'editorActions',
          },
          {
            title: translations.t('editor.manage-my-apps.quick-actions.cta2'),
            actionId: AppManagerEvent.OPEN_DASHBOARD,
            icon: 'appManager_pagesAction',
            type: 'editorActions',
          },
          {
            title: translations.t('editor.manage-my-apps.quick-actions.cta3'),
            actionId: AppManagerEvent.OPEN_SP,
            icon: 'appManager_settingsAction',
            type: 'editorActions',
          },
        );

        managementActionsBuilder.learnMoreAction().set({
          id: 'c4b68831-310b-4475-81af-3b7a092ffd89',
        });

        if (experiments.enabled(Challenges.enablePremium)) {
          managementActionsBuilder.upgradeAction().set({
            upgradeType: 'SITE_UPGRADE',
            upgradeText: translations.t('editor.my-business.premium.text'),
            upgradeLinkText: translations.t('editor.my-business.premium.link'),
          });
        }
      })
      // END Business Panel
      .build()
  );
}
